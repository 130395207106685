export const countryTimezones = {
  'El Salvador': ['America/El_Salvador'],
  Nicaragua: ['America/Managua'],
  Paraguay: ['America/Asuncion'],
  Colombia: ['America/Bogota'],
  Bolivia: ['America/La_Paz'],
  Venezuela: ['America/Caracas'],
  Honduras: ['America/Tegucigalpa'],
  México: [
    'America/Tijuana',
    'America/Hermosillo',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Mexico_City',
    'America/Monterrey',
    'America/Merida',
    'America/Cancun',
    'America/New_York', // USA time zones
    'America/Los_Angeles',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Anchorage',
    'America/Juneau',
    'America/Honolulu',
  ],
  Guatemala: ['America/Guatemala'],
};
