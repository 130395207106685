import { useContext } from 'react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { UserContext } from '../context';
import { useNotify } from './useNotify';

export function useUser() {
  const { user, setUser } = useContext(UserContext);
  const router = useRouter();
  const notify = useNotify();

  const widuSignOut = async (e) => {
    e.preventDefault();
    try {
      signOut()?.then(() => {
        router.push('/');
        setUser(null);
      });
    } catch (err) {
      notify(err.message, 'error');
    }
  };
  return { user, setUser, widuSignOut };
}
