import React from 'react';
import { motion } from 'framer-motion';
import { modal } from '../../animations/modal';
import { cn } from '../../utils/cn';
import BackdropModal from './BackdropModal';

interface ModalProps {
  withoutStyles?: boolean;
  modalOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
  className?: string;
}

export default function Modal({
  withoutStyles = false,
  modalOpen,
  closeModal,
  children,
  className,
}: ModalProps) {
  return (
    <BackdropModal modalOpen={modalOpen} closeModal={closeModal}>
      <motion.div
        variants={modal}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
        className={cn(
          'flex items-center justify-center z-10',
          !withoutStyles &&
            'w-fit h-fit px-8 py-10 flex-col bg-white rounded-lg gap-8 shadow',
          className
        )}
      >
        {children}
      </motion.div>
    </BackdropModal>
  );
}
