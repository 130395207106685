import React from 'react';

type WantSignatureType = '0' | '1' | '2' | '3' | '4';
// 0: Not defined
// 1: Include signatures
// 2: Don't include signatures
// 3: Started filling document with signatures
// 4: Finished filling document with signatures

interface WantSignatureContextProps {
  wantSignature: WantSignatureType;
  setWantSignature: React.Dispatch<React.SetStateAction<WantSignatureType>>;
}

const WantSignatureContext = React.createContext<
  WantSignatureContextProps | undefined
>(undefined);

export const WantSignatureProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const getInitialSignature = (): WantSignatureType => {
    if (typeof window !== 'undefined') {
      const savedSignature = localStorage.getItem(
        'wantSignature'
      ) as WantSignatureType;
      return savedSignature || '0';
    }
    return '0';
  };

  const [wantSignature, setWantSignature] =
    React.useState<WantSignatureType>(getInitialSignature);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const savedSignature = localStorage.getItem(
        'wantSignature'
      ) as WantSignatureType;
      if (savedSignature) {
        setWantSignature(savedSignature);
      }
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('wantSignature', wantSignature);
    }
  }, [wantSignature]);

  return (
    <WantSignatureContext.Provider value={{ wantSignature, setWantSignature }}>
      {children}
    </WantSignatureContext.Provider>
  );
};

export const useWantSignature = (): WantSignatureContextProps => {
  const context = React.useContext(WantSignatureContext);
  if (context === undefined) {
    throw new Error(
      'useWantSignature must be used within a WantSignatureProvider'
    );
  }
  return context;
};
