import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface ModalProps {
  children: React.ReactNode;
  modalOpen: boolean;
  closeModal?: () => void;
}

export default function BackdropModal({
  children,
  closeModal,
  modalOpen,
}: ModalProps) {
  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-[100] h-screen w-full flex justify-center items-center top-0 left-0 bg-black/50 px-5"
          onClick={closeModal}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
