/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { User } from '../models';
import { CURRENT_USER } from '../graphql/queries';

interface IUserContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = React.useState<User | null>(null);
  const { data: sessionData, status } = useSession();
  const router = useRouter();

  const [getCurrentUser, { data: dataUser, loading, error }] = useLazyQuery<{
    currentUser: { user: User };
  }>(CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.currentUser?.user) {
        setUser(data.currentUser.user);
        if (router.pathname === '/login') router?.push('/');
      }
    },
  });

  React.useEffect(() => {
    if (status === 'authenticated' && user) return;

    if (status === 'authenticated' && !user) {
      getCurrentUser({
        variables: {
          data: {
            _id: sessionData?.user?._id,
          },
        },
      });
    } else if (status === 'unauthenticated') {
      setUser(null);
      if (
        router.pathname !== '/login' &&
        router.pathname !== '/register' &&
        !router.pathname.includes('/reset-password') &&
        !router.pathname.includes('/document')
      )
        router.push('/');
    }
  }, [sessionData, status, router.route]);

  const context = React.useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
