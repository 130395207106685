/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Country } from '../models';
import { GET_COUNTRIES } from '../graphql/queries';
import useReadLocalStorage from '../hooks/useReadLocalStorage';
import useLocalStorage from '../hooks/useLocalStorage';
import { countryTimezones } from '../lib/countryTimezones';
import SelectCountryModal from '../components/modals/SelectCountryModal';

interface ICountryContext {
  currentCountry: Partial<Country>;
  setCurrentCountry: React.Dispatch<React.SetStateAction<Partial<Country>>>;
}

export const CountryContext = React.createContext<ICountryContext>({
  currentCountry: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentCountry: () => {},
});

interface CountryContextProviderProps {
  children: React.ReactNode;
}

export function CountryContextProvider({
  children,
}: CountryContextProviderProps) {
  const [isCountryOpen, setIsCountryOpen] = React.useState(false);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  // Function to get the country based on the timezone
  function getCountryByTimezone(timezone) {
    for (const country in countryTimezones) {
      if (countryTimezones[country].includes(timezone)) {
        return country;
      }
    }
    return null; // Return null if no matching country is found
  }

  const { data, loading } = useQuery<{
    countries: Country[];
  }>(GET_COUNTRIES, {
    variables: { filter: { active: true } },
    fetchPolicy: 'network-only',
  });
  const [selectedCountry, setSelectedCountry] = useLocalStorage(
    'currentCountry',
    {} as Partial<Country>
  );
  const currentCountryLocalStorage = useReadLocalStorage('currentCountry');
  const [currentCountry, setCurrentCountry] =
    React.useState<Partial<Country> | null>(
      (currentCountryLocalStorage as Partial<Country>) ??
        ({} as Partial<Country>)
    );
  const context = React.useMemo(
    () => ({ currentCountry, setCurrentCountry }),
    [currentCountry, setCurrentCountry]
  );

  function getCountryByName(countriesData, name) {
    const countries = countriesData?.countries;
    return countries?.find((country) => country.name === name) || null;
  }

  React.useEffect(() => {
    const userTimeZone = getCountryByTimezone(timeZone);
    if (userTimeZone) {
      const country = getCountryByName(data, userTimeZone);
      if (country && !currentCountry?.name) {
        setCurrentCountry(country);
        setSelectedCountry(country);
      }
    }
  }, [data?.countries, loading]);

  React.useEffect(() => {
    if (currentCountry?.name) {
      setIsCountryOpen(false);
    } else {
      setIsCountryOpen(true);
    }
  }, [currentCountry]);

  return (
    <CountryContext.Provider value={context}>
      <SelectCountryModal isOpen={isCountryOpen} setIsOpen={setIsCountryOpen} />
      {children}
    </CountryContext.Provider>
  );
}
