import { gql } from '@apollo/client';

export const LAWYER_FRAGMENT = gql`
  fragment LawyerFragment on Lawyer {
    firm {
      name
      email
      rif
      abbreviation
      phones
      photos
      country {
        name
        abbreviation
        currency {
          name
          symbol
          rate
          active
          _id
          createdAt
          updatedAt
        }
        paymentMethods {
          _id
        }
        contactDetails {
          _id
        }
        active
        _id
        createdAt
        updatedAt
      }
      active
      _id
      createdAt
      updatedAt
    }
    active
    signature
    inpre
    calendlyKey
    _id
    createdAt
    updatedAt
    private
    enterprises {
      _id
      name
      sector
      active
      createdAt
      updatedAt
    }
  }
`;

export const CURRENCY_FRAGMENT = gql`
  fragment CurrencyFragment on Currency {
    _id
    name
    symbol
    rate
    active
  }
`;

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    _id
    type
    subscribed
    birthDate
    profession
    occupation
    gender
    addresses {
      _id
      firstLine
      secondLine
      country {
        name
        abbreviation
        currency {
          ...CurrencyFragment
        }
        paymentMethods {
          name
          commission
          _id
        }
        contactDetails {
          type
          value
          _id
        }
        active
        _id
      }
      state
      city
      postalCode
      isFiscal
    }
    active
    _id
    createdAt
    updatedAt
  }
  ${CURRENCY_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    _id
    slug
    email
    socialId
    enterprise {
      _id
      name
    }
    password
    firstName
    lastName
    calendlyId
    jobPosition
    enterpriseUsers {
      _id
      email
      createdAt
      updatedAt
      active
      firstName
      lastName
    }
    enterpriseSupervisor {
      _id
    }
    enterprise {
      name
      sector
      _id
      createdAt
      updatedAt
      image
    }
    permissions {
      _id
      name
      key
      active
      option
    }
    resetTokenValidity
    resetToken
    dni
    dniType
    phones
    profilePicture
    client {
      ...ClientFragment
    }
    lawyer {
      ...LawyerFragment
    }
    active
    _id
    createdAt
    updatedAt
  }
  ${CLIENT_FRAGMENT}
  ${LAWYER_FRAGMENT}
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    _id
    name
    parent {
      _id
      name
    }
    children {
      parent {
        _id
        name
        children {
          _id
          name
        }
      }
      _id
      name
      children {
        parent {
          _id
          name
          children {
            _id
            name
          }
        }
        _id
        name
        children {
          parent {
            _id
            name
            children {
              _id
              name
            }
          }
          _id
          name
          children {
            parent {
              _id
              name
              children {
                _id
                name
              }
            }
            _id
            name
            children {
              parent {
                _id
                name
                children {
                  _id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const PERMISSION_FRAGMENT = gql`
  fragment PermissionFragment on Permission {
    _id
    name
    key
    option
    active
  }
`;

export const TAX_FRAGMENT = gql`
  fragment TaxFragment on Tax {
    _id
    name
    modifier
    type
    active
    createdAt
    updatedAt
  }
`;

export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    _id
    name
    currency {
      ...CurrencyFragment
    }
    commission
    details {
      name
      value
    }
    active
    createdAt
    updatedAt
  }
  ${CURRENCY_FRAGMENT}
`;

export const FIRM_FRAGMENT = gql`
  fragment FirmFragment on Firm {
    name
    email
    rif
    phones
    photos
    country {
      name
      _id
    }
    active
    _id
    createdAt
    updatedAt
  }
`;
export const COUNTRY_FRAGMENT = gql`
  fragment CountryFragment on Country {
    name
    abbreviation
    currency {
      ...CurrencyFragment
    }
    paymentMethods {
      _id
      name
    }
    contactDetails {
      _id
      type
      value
    }
    active
    _id
    createdAt
    updatedAt
  }
  ${CURRENCY_FRAGMENT}
`;

export const VARIABLE_FRAGMENT = gql`
  fragment VariableFragment on Variable {
    _id
    name
    question
    tagName
    placeholder
    type
    options
    precreatedOptions
    isOptional
    active
  }
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    name
    abbreviation
    requiresVisa
    priority
    enterprise {
      _id
      name
      image
    }
    category {
      ...CategoryFragment
    }
    taxes {
      ...TaxFragment
    }
    requiresFormalization
    autoApproval
    user {
      ...UserFragment
    }
    price
    legalFee
    description
    requirements
    version
    requiresSignature
    questionnaires {
      title
      description
      questions {
        ...VariableFragment
      }
      country {
        ...CountryFragment
      }
      active
      _id
      createdAt
      updatedAt
    }
    template
    history {
      title
      user {
        ...UserFragment
      }
      details {
        name
        value
        _id
      }
      active
      _id
      createdAt
      updatedAt
    }
    FAQ {
      question
      answer
      _id
    }
    active
    _id
    createdAt
    updatedAt
    slug
    signatureRoles {
      _id
      firmType
      roles
    }
  }
  ${CATEGORY_FRAGMENT}
  ${TAX_FRAGMENT}
  ${USER_FRAGMENT}
  ${VARIABLE_FRAGMENT}
  ${COUNTRY_FRAGMENT}
`;

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    code
    pdfUrl
    user {
      ...UserFragment
    }
    document {
      ...DocumentFragment
    }
    variableValues {
      value
      variable {
        ...VariableFragment
      }
      active
      _id
      createdAt
      updatedAt
    }
    status
    history {
      title
      user {
        ...UserFragment
      }
      details {
        name
        value
        _id
      }
      active
      _id
      createdAt
      updatedAt
    }
    modality
    promotions {
      name
      type
      modifier
      code
      active
      _id
      createdAt
      updatedAt
    }
    active
    _id
    createdAt
    updatedAt
    inviteToSign {
      _id
      to {
        email
        order
        role
      }
      viewers {
        email
        order
        role
      }
    }
    hasSignature
    signnowDocumentId
  }
  ${DOCUMENT_FRAGMENT}
  ${VARIABLE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const FINAL_PDF_FRAGMENT = gql`
  fragment FinalPdfFragment on FinalPdf {
    valueTemplate
  }
`;

export const ENTERPRISE_FRAGMENT = gql`
  fragment EnterpriseFragment on Enterprise {
    name
    sector
    image
    country {
      ...CountryFragment
    }
    _id
    createdAt
    updatedAt
  }
  ${COUNTRY_FRAGMENT}
`;

export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFragment on Appointment {
    _id
    createdAt
    updatedAt
    active
    name
    # description
    price
    enterprise {
      ...EnterpriseFragment
    }
    country {
      ...CountryFragment
    }
    duration
  }
  ${ENTERPRISE_FRAGMENT}
  ${COUNTRY_FRAGMENT}
`;

export const PROMOTION_FRAGMENT = gql`
  fragment PromotionFragment on Promotion {
    name
    type
    modifier
    code
    active
    _id
    createdAt
    updatedAt
  }
`;

export const APPOINTMENT_ORDER_FRAGMENT = gql`
  fragment AppointmentOrderFragment on AppointmentOrder {
    _id
    createdAt
    updatedAt
    active
    # Calendly
    meetingId
    eventGuests
    startTime
    eventMemberships {
      _id
      email
      user
    }
    meetingId
    date
    calendarEvent {
      _id
      kind
      externalId
    }
    appointment {
      ...AppointmentFragment
    }
    client {
      ...UserFragment
    }
    lawyer {
      ...UserFragment
    }
    docOrders {
      ...OrderFragment
    }
    documents {
      ...DocumentFragment
    }
    promotions {
      ...PromotionFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${PROMOTION_FRAGMENT}
`;
export const PAYMENT_FRAGMENT = gql`
  fragment PaymentFragment on Payment {
    reference
    method {
      ...PaymentMethodFragment
    }
    order {
      ...OrderFragment
    }
    amount
    status
    details {
      name
      value
      _id
    }
    active
    _id
    createdAt
    updatedAt
    appOrder {
      ...AppointmentOrderFragment
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const PAYMENT_TYPE_FRAGMENT = gql`
  fragment PaymentTypeFragment on PaymentType {
    _id
    reference
    order {
      ...OrderFragment
    }
    amount
    status
    details {
      name
      value
    }
    createdAt
    updatedAt
  }
  ${ORDER_FRAGMENT}
`;

export const SHOP_CART_FRAGMENT = gql`
  fragment ShopCartFragment on ShopCart {
    _id
    createdAt
    updatedAt
    active
    client {
      ...UserFragment
    }
    orders {
      ...OrderFragment
    }
    appOrders {
      ...AppointmentOrderFragment
    }
  }
  ${USER_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${APPOINTMENT_ORDER_FRAGMENT}
`;
