/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import {
  CountryContextProvider,
  ThemeContextProvider,
  ToastContextProvider,
  UserContextProvider,
} from '../context';
import { useApollo } from '../hooks';
import '../style.css';
import Script from 'next/script';
import { WantSignatureProvider } from '../context/WantSignatureContext';

const monserrat = Montserrat({ subsets: ['latin', 'latin-ext'] });

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: AppProps<any>) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // On page load or when changing themes, best to add inline in `head` to avoid FOUC
      if (
        localStorage.theme === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }

      // Whenever the user explicitly chooses light mode
      localStorage.theme = 'light';

      // Whenever the user explicitly chooses dark mode
      localStorage.theme = 'dark';

      // Whenever the user explicitly chooses to respect the OS preference
      localStorage.removeItem('theme');
    }
  }, []);

  return (
    <SessionProvider session={pageProps.session} refetchInterval={0}>
      <ApolloProvider client={apolloClient}>
        <>
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-TKSVDZR');`,
            }}
          />
          <Script
            id="A3bK9zX1pR7uV5wE2tO8dY4mQ0"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", 'jmet98l8zl');`,
            }}
          />
          <Head>
            <title>Widú Legal</title>
          </Head>
          <ThemeContextProvider>
            <ToastContextProvider>
              <CountryContextProvider>
                <UserContextProvider>
                  <WantSignatureProvider>
                    <div className={monserrat.className}>
                      <Component {...pageProps} err={err} />
                    </div>
                  </WantSignatureProvider>
                </UserContextProvider>
              </CountryContextProvider>
            </ToastContextProvider>
          </ThemeContextProvider>
        </>
      </ApolloProvider>
    </SessionProvider>
  );
}

export default MyApp;
