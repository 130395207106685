/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useCountry } from '../../hooks/useCountry';

import FlagButton from '../common/FlagButton';
import Modal from '../common/Modal';

interface SelectCountryModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SelectCountryModal({ isOpen, setIsOpen }: SelectCountryModalProps) {
  const { countries, loadingCountries, selectCountry, currentCountry } =
    useCountry();

  return (
    <Modal
      modalOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      className="max-w-lg w-fit"
    >
      <h3 className="text-gray-900 md:text-lg font-semibold w-full text-center">
        Selecciona un país
      </h3>
      {loadingCountries || !countries ? (
        <div className="w-full h-96 flex justify-center items-center">
          <TailSpin
            height={80}
            width={80}
            color="#214497"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <div className="flex flex-row md:gap-8 gap-6 flex-wrap justify-center">
          {countries?.countries?.map((country) => (
            <FlagButton
              key={country._id}
              withFlag={country}
              selectedCountry={currentCountry?._id}
              iconClassName="md:w-10 md:h-10 w-7 h-7"
              onClick={(e) => {
                e.preventDefault();
                selectCountry(country);
                setIsOpen(false);
              }}
            >
              {country?.name}
            </FlagButton>
          ))}
        </div>
      )}
    </Modal>
  );
}

export default SelectCountryModal;
