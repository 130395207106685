import React from 'react';
import {
  VenezuelaFlag,
  ParaguayFlag,
  BoliviaFlag,
  ColombianFlag,
  ElSalvadorFlag,
  GuatemalaFlag,
  HondurasFlag,
  MexicoFlag,
  NicaraguaFlag,
} from '@avila-tek/ui';

import { Country } from '../models';

const getCountryFlag = (country: Partial<Country>, className) => {
  switch (country?.name) {
    case 'Venezuela':
      return <VenezuelaFlag className={` ${className}`} />;
    case 'Paraguay':
      return <ParaguayFlag className={` ${className}`} />;
    case 'El Salvador':
      return <ElSalvadorFlag className={` ${className}`} />;
    case 'Bolivia':
      return <BoliviaFlag className={` ${className}`} />;
    case 'Colombia':
      return <ColombianFlag className={` ${className}`} />;
    case 'Honduras':
      return <HondurasFlag className={` ${className}`} />;
    case 'Nicaragua':
      return <NicaraguaFlag className={` ${className}`} />;
    case 'México':
      return <MexicoFlag className={` ${className}`} />;
    case 'Guatemala':
      return <GuatemalaFlag className={` ${className}`} />;

    default:
      return (
        <div
          className={`bg-neutral-200 rounded-full flex items-center justify-center ${className}`}
        >
          <p className="">{country?.abbreviation}</p>
        </div>
      );
  }
};

export default getCountryFlag;
