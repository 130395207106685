import React from 'react';
import { Country } from '../../models';
import { cn } from '../../utils/cn';
import getCountryFlag from '../../lib/getCountryFlag';

interface FlagButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  withFlag?: Country;
  iconClassName?: string;
  selectedCountry?: string;
}

function FlagButton({
  children,
  type,
  disabled,
  className,
  withFlag,
  iconClassName,
  selectedCountry,
  ...rest
}: FlagButtonProps) {
  return (
    <button
      type={type}
      className={cn(
        'bg-white cursor-pointer flex text-sm font-medium text-gray-600 items-center border border-gray-200 shadow-sm w-28 flex-col gap-2 p-4 rounded-lg',
        selectedCountry === withFlag?._id
          ? 'bg-gray-50 text-primary-700 font-semibold'
          : 'hover:bg-gray-50 hover:text-gray-800',
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {withFlag ? getCountryFlag(withFlag, iconClassName) : null}
      {children}
    </button>
  );
}

export default FlagButton;
