import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  DOCUMENT_FRAGMENT,
  VARIABLE_FRAGMENT,
  ORDER_FRAGMENT,
  CLIENT_FRAGMENT,
  PAYMENT_METHOD_FRAGMENT,
  COUNTRY_FRAGMENT,
  FINAL_PDF_FRAGMENT,
  CURRENCY_FRAGMENT,
  PAYMENT_FRAGMENT,
  PAYMENT_TYPE_FRAGMENT,
  PROMOTION_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  APPOINTMENT_ORDER_FRAGMENT,
  SHOP_CART_FRAGMENT,
  CATEGORY_FRAGMENT,
  ENTERPRISE_FRAGMENT,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER($data: CurrentUserInput!) {
    currentUser(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

// Documents
export const GET_DOCUMENT = gql`
  query GET_DOCUMENT($filter: FilterFindOneDocumentInput!) {
    document(filter: $filter) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS = gql`
  query GET_DOCUMENTS(
    $filter: FilterFindManyDocumentInput
    $sort: SortFindManyDocumentInput
  ) {
    documents(filter: $filter, sort: $sort, limit: 2000) {
      ...DocumentFragment
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

// Variables
export const GET_VARIABLES = gql`
  query GET_VARIABLES(
    $filter: FilterFindManyVariableInput
    $sort: SortFindManyVariableInput
  ) {
    variables(filter: $filter, sort: $sort) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_VARIABLE = gql`
  query GET_VARIABLE($filter: FilterFindOneVariableInput!) {
    variable(filter: $filter) {
      ...VariableFragment
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_VARIABLES_LIST = gql`
  query GET_VARIABLES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyVariableInput
  ) {
    variablePagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...VariableFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VARIABLE_FRAGMENT}
`;

export const GET_ORDER = gql`
  query GET_ORDER(
    $filter: FilterFindOneOrderInput
    $skip: Int
    $sort: SortFindOneOrderInput
  ) {
    order(filter: $filter, skip: $skip, sort: $sort) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_ORDERS = gql`
  query GET_ORDERS(
    $filter: FilterFindManyOrderInput
    $skip: Int
    $limit: Int
    $sort: SortFindManyOrderInput
  ) {
    orders(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_CLIENT = gql`
  query GET_CLIENT(
    $filter: FilterFindOneClientInput
    $skip: Int
    $sort: SortFindOneClientInput
  ) {
    client(filter: $filter, skip: $skip, sort: $sort) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS(
    $filter: FilterFindManyPaymentMethodInput
    $skip: Int
    $limit: Int
    $sort: SortFindManyPaymentMethodInput
  ) {
    paymentMethods(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
      ...PaymentMethodFragment
    }
  }
  ${PAYMENT_METHOD_FRAGMENT}
`;

export const GET_COUNTRIES = gql`
  query GET_COUNTRIES(
    $filter: FilterFindManyCountryInput
    $skip: Int
    $limit: Int = 100
    $sort: SortFindManyCountryInput
  ) {
    countries(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
      ...CountryFragment
    }
  }
  ${COUNTRY_FRAGMENT}
`;

export const GET_FINAL_PDF = gql`
  query GET_FINAL_PDF($data: GetFinalPdfInput) {
    getFinalPdf(data: $data) {
      ...FinalPdfFragment
    }
  }
  ${FINAL_PDF_FRAGMENT}
`;

export const GET_CURRENCY = gql`
  query GET_CURRENCY(
    $filter: FilterFindOneCurrencyInput
    $skip: Int
    $sort: SortFindOneCurrencyInput
  ) {
    currency(filter: $filter, skip: $skip, sort: $sort) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const GET_ALL_ORDERS = gql`
  query GET_ALL_ORDERS($data: GetAllOrdersData) {
    getAllOrders(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS(
    $filter: FilterFindManyPaymentInput
    $skip: Int
    $limit: Int = 100
    $sort: SortFindManyPaymentInput
  ) {
    payments(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const GET_PAYMENT = gql`
  query GET_PAYMENT($filter: FilterFindOnePaymentInput) {
    payment(filter: $filter) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const GET_PAYMENTS_BY_USER = gql`
  query GET_PAYMENTS_BY_USER($data: GetPaymentByUserInput) {
    getPaymentsByUser(data: $data) {
      data {
        ...PaymentTypeFragment
      }
    }
  }
  ${PAYMENT_TYPE_FRAGMENT}
`;

export const GET_PROMOTION = gql`
  query GET_PROMOTION(
    $filter: FilterFindOnePromotionInput
    $skip: Int
    $sort: SortFindOnePromotionInput
  ) {
    promotion(filter: $filter, skip: $skip, sort: $sort) {
      ...PromotionFragment
    }
  }
  ${PROMOTION_FRAGMENT}
`;

// Appointments
export const GET_APPOINTMENT = gql`
  query GET_APPOINTMENT($filter: FilterFindOneAppointmentInput!) {
    appointment(filter: $filter) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS(
    $filter: FilterFindManyAppointmentInput
    $sort: SortFindManyAppointmentInput
  ) {
    appointments(filter: $filter, sort: $sort) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENT_ORDER = gql`
  query GET_APPOINTMENT_ORDER($filter: FilterFindOneAppointmentOrderInput!) {
    appointmentOrder(filter: $filter) {
      ...AppointmentOrderFragment
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const GET_APPOINTMENT_ORDERS = gql`
  query GET_APPOINTMENT_ORDERS(
    $filter: FilterFindManyAppointmentOrderInput
    $sort: SortFindManyAppointmentOrderInput
  ) {
    appointmentOrders(filter: $filter, sort: $sort, limit: 1000) {
      ...AppointmentOrderFragment
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const GET_SHOP_CART = gql`
  query GET_SHOP_CART($filter: FilterFindOneShopCartInput!) {
    shopCart(filter: $filter) {
      ...ShopCartFragment
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

export const GET_SHOP_CARTS = gql`
  query GET_SHOP_CARTS(
    $filter: FilterFindManyShopCartInput
    $sort: SortFindManyShopCartInput
  ) {
    shopCarts(filter: $filter, sort: $sort) {
      ...ShopCartFragment
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

// Lawyers
export const GET_LAWYERS = gql`
  query GET_LAWYERS {
    lawyers {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    categories {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_ENTERPRISES = gql`
  query GET_ENTERPRISES(
    $filter: FilterFindManyEnterpriseInput
    $sort: SortFindManyEnterpriseInput
  ) {
    enterprises(filter: $filter, sort: $sort) {
      ...EnterpriseFragment
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;

export const GET_ENTERPRISE_LAWYERS = gql`
  query GET_ENTERPRISE_LAWYERS($data: GetEnterpriseLawyersInput) {
    getEnterpriseLawyers(data: $data) {
      items {
        _id
        slug
        email
        firstName
        lastName
        dniType
        lawyer {
          _id
          private
          firm {
            _id
            name
          }
          active
          calendlyKey
        }
        client {
          stripeId
          type
          birthDate
          profession
          occupation
          gender

          subscribed
          active
          _id
          createdAt
          updatedAt
        }
        calendlyId
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CLIENTS = gql`
  query GET_CLIENTS {
    clients {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CLIENT_USER = gql`
  query GET_CLIENT_USER($filter: FilterFindOneClientInput!) {
    client(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USERS = gql`
  query GET_USERS(
    $filter: FilterFindManyUserInput
    $sort: SortFindManyUserInput
  ) {
    users(filter: $filter, sort: $sort, limit: 2000) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const DOCUMENT_PAGINATION = gql`
  query DOCUMENT_PAGINATION(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyDocumentInput
    $sort: SortFindManyDocumentInput
  ) {
    documentPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      pageInfo {
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
      }
      items {
        ...DocumentFragment
      }
      count
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS_PAGINATION = gql`
  query GET_DOCUMENTS_PAGINATION($data: GetDocumentsInput) {
    getDocuments(data: $data) {
      items {
        ...DocumentFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_FILTERED_CATEGORIES = gql`
  query GET_FILTERED_CATEGORIES($data: GetFilteredCategoriesInput) {
    getFilteredCategories(data: $data) {
      categories {
        category {
          name
          _id
        }
        count
      }
    }
  }
`;
